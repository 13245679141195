import React, { useState, useEffect } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { isEmpty, map } from 'lodash';

import {
  IAuditEntity,
  IBuildingObject,
  IGeogroupingObject,
  IProductLocationAttributesChanges,
} from 'types/productCatalogTypes';
import { getProductLocationAttributesAudit } from 'networking/productCatalog/productRequests';
import { useApiHook } from 'hooks/useApiHook';
import { getUsers } from 'networking/operatorService/userByUuidRequests';
import { IEmployee } from 'networking/fetchConfig';
import { IPaginationatedResponse } from 'networking/operatorService/operatorServiceTypes';
import { getAllPublishedGeogroupings, getAllPublishedBuildings } from 'networking/productCatalog/buildingRequests';
import { IEditProductTabCommonProps } from '../editProductTabsConfig';
import { LocationAttributesChangesLable } from './AuditLocationLabels';
import { AuditLocationChangesTable } from './AuditLocationChangesTable';
import { userGetter } from './utils';

import './styles.scss';

const PAGE_SIZE = 350;

export const LocationAttributes = ({ product }: IEditProductTabCommonProps) => {
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersError, setUsersError] = useState<string | undefined>();
  const [usersResponse, setUsersResponse] = useState<IPaginationatedResponse<IEmployee> | undefined>();
  const [auditRecordsLoading, setAuditRecordsLoading] = useState(false);
  const [auditRecordsError, setAuditRecordsError] = useState<string | undefined>();
  const [auditRecords, setAuditRecords] = useState<IAuditEntity<IProductLocationAttributesChanges>[]>([]);
  const [offset, setOffset] = useState(0);
  const [stop, setStop] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setAuditRecordsLoading(true);
        const {
          data: auditLogs,
          status,
          error,
        } = await getProductLocationAttributesAudit(product.uuid, PAGE_SIZE, offset);
        if (error) {
          setAuditRecordsError(error);
        }
        if (auditLogs?.length) {
          setAuditRecords([...auditRecords, ...(auditLogs as IAuditEntity<IProductLocationAttributesChanges>[])]);
        } else if (status === 200) {
          setStop(true);
        }
      } finally {
        setAuditRecordsLoading(false);
      }
    };
    getData();
  }, [offset]);
  const geogroupings = useApiHook<IGeogroupingObject[]>(getAllPublishedGeogroupings, [], []);
  const buildings = useApiHook<IBuildingObject[]>(getAllPublishedBuildings, [], []);
  const loadMore = () => setOffset(offset + PAGE_SIZE + 1);

  useAsyncEffect(
    async (isActive) => {
      const userUuids = map(auditRecords, 'user');
      if (!isActive() || isEmpty(userUuids)) return;
      try {
        setUsersLoading(true);
        const response = await getUsers(userUuids);
        response.error && setUsersError(response.error);
        response.data && setUsersResponse(response.data);
      } catch (e) {
        setUsersLoading(false);
      } finally {
        setUsersLoading(false);
      }
    },
    [auditRecords],
  );

  const getUserData = userGetter(usersResponse?.content);

  return (
    <AuditLocationChangesTable
      locationAuditData={auditRecords ?? []}
      auditRecordsLoading={auditRecordsLoading}
      loadMore={loadMore}
      stop={stop}
      usersLoading={usersLoading}
      geogroupingsData={geogroupings.data ?? []}
      buildingsData={buildings.data ?? []}
      locationIdType="locationUuid"
      treeNodeLabelComponent={LocationAttributesChangesLable}
      getUserData={getUserData}
    />
  );
};
