import { stringify } from 'query-string';
import { IExtendedFetchResult, RequestMethods, IEmployee } from 'networking/fetchConfig';
import { operatorServiceFetch } from './operatorServiceFetch';
import { IPaginationatedResponse } from './operatorServiceTypes';

export function getUserByUuid(uuid: IEmployee['uuid']): Promise<IExtendedFetchResult<IEmployee>> {
  return operatorServiceFetch<IEmployee>(RequestMethods.GET, `/v1/employees/${uuid}`);
}
export function getUsers(uuids: string[]): Promise<IExtendedFetchResult<IPaginationatedResponse<IEmployee>>> {
  const query = stringify({ uuids });
  return operatorServiceFetch<IPaginationatedResponse<IEmployee>>(RequestMethods.GET, `/v1/employees?${query}`);
}
